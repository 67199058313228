<template>
  <div class="login-section row">
    <div class="col-12 text-center">
      <h2 v-if="currentStoreId">
        Welcome to {{ configs.site.name }}. We're excited you're here.
      </h2>
    </div>
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-4">
          <div class="text-center text-muted mb-4">
            <div>Add New Password</div>
          </div>
          <form role="form">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              id="password"
              name="password"
              v-model="model.password"
            ></base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Confirm password"
              type="password"
              v-on:keyup="confirmPasswordInputChanged($event)"
              v-model="model.password_confirmation"
            ></base-input>

            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              size="invisible"
              sitekey="6Lc6lFUcAAAAAECJb-i1Uy0lZCW8pvkalDcQ-50m"
            ></vue-recaptcha>

            <div class="col">
              <div class="text-danger text-center">
                <small>{{ localError }}</small>
              </div>
            </div>

            <div class="text-center">
              <base-button
                type="primary"
                class="btn-register mt-4"
                :disabled="loading"
                @click="submit()"
              >
                <div class="loader" v-if="loading"></div>
                <span v-else>Create New Password</span>
              </base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <!-- <a href="#" class="text-light">
                        <small>Forgot password?</small>
          </a>-->
        </div>
        <div class="col-6 text-right">
          <router-link to="/app/login" class="text-primary">
            <small>Login into your account</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import { MESSAGES, AVAILABLE_INTEGRATIONS } from "@/constants";
import Configs from "../configs";

export default {
  name: "addPassword",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      configs: Configs,
      model: {
        email: "",
        // verify: "",
        // verify_token: "",
        // verify_email: "",
        // invite_token: "",
        password: "",
        password_confirmation: "",
        // shop: "",
        // state: "",
        // promo: "",
      },
      currentStoreId: null,
      terms_conditions: false,
      loading: false,
      localError: null,
    };
  },
  props: {
    store_id: {
      type: String,
      description: "store id",
    },
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert,
    }),
  },

  mounted() {
    //check if getting token and email correctly

    this.$store.dispatch("user/forgetPasswordCode", this.$route.query);

    // to reload userpilot
    this.$store.dispatch("user/setRoute", this.$route.path);
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }
    if (this.$route.query.invite_token) {
      this.model.invite_token = this.$route.query.invite_token;
    }
    if (this.$route.query.promo) {
      this.model.promo = this.$route.query.promo;
    }
    if (this.store_id === AVAILABLE_INTEGRATIONS.shopify.id) {
      this.currentStoreId = this.store_id;
      this.model.shop = this.$route.query.shop;
      this.model.state = this.$route.query.state;
    } else {
      this.currentStoreId = null;
    }
    let recaptchaScript = document.createElement("script");
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    document.body.appendChild(recaptchaScript);
  },
  methods: {
    async submit() {
      const { email, password, password_confirmation, shop } = this.model;
      if (!password || !password_confirmation) {
        this.localError = "Missing password.";
        return;
      } else if (password !== password_confirmation) {
        this.localError = "Passwords do not match.";
        return;
      }

      this.$refs.recaptcha.execute();
    },

    async onCaptchaVerified(recaptchaToken) {
      this.loading = true;
      this.$refs.recaptcha.reset();

      //change to new reset password route
      try {
        const res = await this.$store.dispatch("user/addNewPassword", {
          ...this.model,
          recaptcha_token: recaptchaToken,
        });
        this.loading = false;
        this.localError = null;

        this.$router.push("/app/influencers");
        this.$router.go();

        if (res) {
          console.log("res final " + res);

          //if res true redirect to confirm page

          // if (res.shopify_success) {
          //   window.location.replace(`/app/settings/integrations`);
          // } else if (res.shop) {
          //   window.location.replace(
          //     `/app/settings/integrations/shopify?shop=${res.shop}&state=${
          //       res.state ? res.state : ""
          //     }`
          //   );
          // } else {
          //   window.location.replace("/app");
          // }
        }
      } catch (err) {
        this.loading = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__registration-failed"],
            alertClass: "danger",
          });
        }
      }
    },
    confirmPasswordInputChanged(e) {
      if (e.keyCode === 13) {
        this.submit();
      }
    },
    onCaptchaExpired: function() {
      this.$refs.recaptcha.reset();
    },
    validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/pages/register";
</style>
