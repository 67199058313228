<template>
  <div class="login-section row">
    <div class="col-12 text-center">
      <h2 v-if="currentStoreId">
        Welcome to {{ configs.site.name }}. We're excited you're here.
      </h2>
    </div>
    <div class="">
      <div class="">
        <div class="card-body px-lg-5 py-lg-4">
          <div class="text-center title-auth mb-4">
            <template v-if="currentStoreId">
              <p>
                Create an account to sync with Shopify.<br />
                Already have an account?<router-link
                  :to="'/app/login?shop=' + (model.shop ? model.shop : '')"
                  class="text-primary ml-2"
                  >Login</router-link
                >
              </p>
            </template>
            <template v-else>Register</template>
          </div>
          <div class="auth-form">
            <form role="form">
              <base-input
                class="input-group-alternative auth-input mb-3"
                placeholder="Email"
                name="email"
                id="email"
                v-model="model.email"
              ></base-input>

              <base-input
                v-if="currentStoreId"
                class="input-group-alternative auth-input mb-3"
                placeholder="Store Website"
                v-model="model.shop"
              ></base-input>

              <base-input
                class="input-group-alternative auth-input mb-3"
                placeholder="Password"
                type="password"
                id="password"
                name="password"
                v-model="model.password"
              ></base-input>

              <base-input
                class="input-group-alternative auth-input"
                placeholder="Confirm password"
                type="password"
                v-on:keyup="confirmPasswordInputChanged($event)"
                v-model="model.password_confirmation"
              ></base-input>
              <div class="password-tips">
                Password requirements:
                <ul>
                  <li>at least 1 digit</li>
                  <li>at least 1 capital</li>
                  <li>at least 1 lower</li>
                  <li>total length need to be between 8 to 40 characters</li>
                </ul>
              </div>

              <div class="row my-4">
                <div class="col-12">
                  <base-checkbox
                    class="custom-control-alternative"
                    v-model="terms_conditions"
                  >
                    <span class="text-muted">
                      I agree with the
                      <a href="https://ninjainfluence.com/wp/terms-of-service/"
                        target="_blank" >Terms and Conditions</a
                      >
                    </span>
                  </base-checkbox>
                </div>
              </div>

              <vue-recaptcha
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                size="invisible"
                sitekey="6Lc6lFUcAAAAAECJb-i1Uy0lZCW8pvkalDcQ-50m"
              ></vue-recaptcha>

              <div class="col">
                <div class="text-danger text-center">
                  <small>{{ localError }}</small>
                </div>
              </div>

              <div class="text-center">
                <base-button
                  type="primary"
                  class="btn-auth mt-4"
                  :disabled="loading"
                  @click="submit()"
                >
                  <div class="loader" v-if="loading"></div>
                  <span v-else>Create account</span>
                </base-button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <!-- <a href="#" class="text-light">
                        <small>Forgot password?</small>
          </a>-->
        </div>
        <div class="col-6 text-right">
          <router-link to="/app/login" class="text-black">
            <small>Login into your account</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import { MESSAGES, AVAILABLE_INTEGRATIONS } from "@/constants";
import Configs from "../configs";

export default {
  name: "register",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      configs: Configs,
      model: {
        email: "",
        invite_token: "",
        password: "",
        password_confirmation: "",
        shop: "",
        state: "",
        promo: "",
      },
      currentStoreId: null,
      terms_conditions: false,
      loading: false,
      localError: null,
    };
  },
  props: {
    store_id: {
      type: String,
      description: "store id",
    },
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert,
    }),
  },

  mounted() {
    // to reload userpilot
    this.$store.dispatch("user/setRoute", this.$route.path);
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }
    if (this.$route.query.invite_token) {
      this.model.invite_token = this.$route.query.invite_token;
    }
    if (this.$route.query.promo) {
      this.model.promo = this.$route.query.promo;
    }
    if (this.store_id === AVAILABLE_INTEGRATIONS.shopify.id) {
      this.currentStoreId = this.store_id;
      this.model.shop = this.$route.query.shop;
      this.model.state = this.$route.query.state;
    } else {
      this.currentStoreId = null;
    }
    let recaptchaScript = document.createElement("script");
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    document.body.appendChild(recaptchaScript);
  },
  methods: {
    async submit() {
      const { email, password, password_confirmation, shop } = this.model;
      if (!email || !password || !password_confirmation) {
        this.localError = "Missing email or password.";
        return;
      } else if (password !== password_confirmation) {
        this.localError = "Passwords do not match.";
        return;
      } else if (this.currentStoreId && !shop) {
        this.localError = "Missing store website.";
        return;
      } else if (!this.terms_conditions) {
        this.localError = "You must agree to the Terms and Conditions.";
        return;
      }
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      this.loading = true;
      this.$refs.recaptcha.reset();
      try {
        const res = await this.$store.dispatch("user/signUp", {
          ...this.model,
          recaptcha_token: recaptchaToken,
        });
        this.loading = false;
        this.localError = null;
        if (res) {
          if (res.shopify_success) {
            window.location.replace(`/app/settings/integrations`);
          } else if (res.shop) {
            window.location.replace(
              `/app/settings/integrations/shopify?shop=${res.shop}&state=${
                res.state ? res.state : ""
              }`
            );
          } else {
            window.location.replace("/app");
          }
        }
      } catch (err) {
        this.loading = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__registration-failed"],
            alertClass: "danger",
          });
        }
      }
    },
    confirmPasswordInputChanged(e) {
      if (e.keyCode === 13) {
        this.submit();
      }
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/pages/register";
</style>
