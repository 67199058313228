<template>
  <div class="login-section row">
    <div class="col-12 text-center">
      <h2 v-if="currentStoreId">
        Welcome to {{ configs.site.name }}. We're excited you're here.
      </h2>
    </div>
    <div class="col-md-7">
      <div class="shadow border-0">
        <div class="card-body px-lg-5 py-lg-4">
          <div class="text-center mb-4 title-forgot">
            <div>Reset Your Password</div>
          </div>
          <form role="form">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Email"
              name="email"
              id="email"
              v-model="model.email"
            ></base-input>

            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              size="invisible"
              sitekey="6Lc6lFUcAAAAAECJb-i1Uy0lZCW8pvkalDcQ-50m"
            ></vue-recaptcha>

            <div class="col">
              <div class="text-danger text-center">
                <small>{{ localError }}</small>
              </div>
            </div>

            <div class="text-center" v-if="isSend">
              <base-button
                type="primary"
                class="btn btn-auth btn-primary btn-forgot"
                :disabled="loading"
                @click="submit()"
              >
                <div class="loader" v-if="loading"></div>
                <span v-else>Reset Password</span>
              </base-button>
            </div>
          </form>
        </div>
        <div class="text-center mb-4" v-if="showMessageEmail">
          We've sent a reset link to your email
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6"></div>
        <div class="col-6 text-right">
          <router-link to="/app/login" class="text-black">
            <small>Login into your account</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import { MESSAGES, AVAILABLE_INTEGRATIONS } from "@/constants";
import Configs from "../configs";

export default {
  name: "forgot",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      configs: Configs,
      model: {
        email: "",
        // invite_token: "",
        // password: "",
        // password_confirmation: "",
        // shop: "",
        // state: "",
        // promo: "",
      },
      isSend: true,
      currentStoreId: null,
      terms_conditions: false,
      loading: false,
      localError: null,
      showMessageEmail: false,
    };
  },
  props: {
    store_id: {
      type: String,
      description: "store id",
    },
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert,
    }),
  },

  mounted() {
    // to reload userpilot
    this.$store.dispatch("user/setRoute", this.$route.path);
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }
    if (this.$route.query.invite_token) {
      this.model.invite_token = this.$route.query.invite_token;
    }
    if (this.$route.query.promo) {
      this.model.promo = this.$route.query.promo;
    }
    if (this.store_id === AVAILABLE_INTEGRATIONS.shopify.id) {
      this.currentStoreId = this.store_id;
      this.model.shop = this.$route.query.shop;
      this.model.state = this.$route.query.state;
    } else {
      this.currentStoreId = null;
    }
    let recaptchaScript = document.createElement("script");
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    document.body.appendChild(recaptchaScript);
  },
  methods: {
    async submit() {
      const { email, password, password_confirmation, shop } = this.model;
      let validate_Email = this.validateEmail(email);
      if (!email) {
        this.localError = "Missing email";
        return;
      }
      if (!validate_Email) {
        this.localError = "Invalid email";
        return;
      }

      this.$refs.recaptcha.execute();
    },

    async onCaptchaVerified(recaptchaToken) {
      this.loading = true;
      this.$refs.recaptcha.reset();

      //change to new reset password route

      try {
        const res = await this.$store.dispatch("user/forgotPassword", {
          ...this.model,
          recaptcha_token: recaptchaToken,
        });
        this.loading = false;
        this.localError = null;
        if (res.success) {
          this.showMessageEmail = true;
          this.isSend = false;
          //show message for the user that he need to check his email
        }
      } catch (err) {
        console.log("err", err);
        console.log("status code", err.response);

        this.loading = false;

        if (err.response.status === 409) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__forgot-email"],
            alertClass: "danger",
          });
        } else {
          // this.$emit("conflict");
        }


        // this.loading = false;
        // if (!this.showAlert) {
        //   this.$store.dispatch("setAlert", {
        //     showAlert: true,
        //     content: MESSAGES["user__registration-failed"],
        //     alertClass: "danger",
        //   });
        // }




      }
    },
    confirmPasswordInputChanged(e) {
      if (e.keyCode === 13) {
        this.submit();
      }
    },
    onCaptchaExpired: function() {
      this.$refs.recaptcha.reset();
    },
    validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/pages/register";
</style>
