<template>
  <div class="signup-section row">
    <!-- <div class="steps-progress-container">
      <div class="steps-progress">
        <div
          class="progress-circle"
          :class="{
            active: [
              SignUpSteps.WelcomeForm,
              SignUpSteps.PricingPlan,
              SignUpSteps.CreditCard
            ].includes(signupStep)
          }"
        ></div>
        <div
          class="progress-circle"
          :class="{
            active: [SignUpSteps.PricingPlan, SignUpSteps.CreditCard].includes(
              signupStep
            )
          }"
        ></div>
        <div
          class="progress-circle"
          :class="{ active: signupStep == SignUpSteps.CreditCard }"
        ></div>
      </div>
    </div> -->

    <div class="card-body">
      <div class="signup-steps">
        <div class="signup-step welcome-form-step">
          <div class="page-title">NinjaInfluence</div>
          <div class="step-title">Sign up for a trial</div>

          <form>
            <div class="step-content">
              <base-input
                class="input-group-alternative signup-input mb-3"
                placeholder="Email"
                name="email"
                id="email"
                autocomplete="email"
                v-model="model.email"
                :error="signupErrors.email"
              ></base-input>

              <base-input
                class="input-group-alternative signup-input mb-3"
                placeholder="Full name"
                name="fullname"
                id="fullname"
                autocomplete="name"
                v-model="model.fullName"
                :error="signupErrors.fullName"
              ></base-input>

              <base-input
                class="input-group-alternative signup-input mb-3"
                placeholder="Company name"
                name="companyname"
                id="companyname"
                autocomplete="organization"
                v-model="model.companyName"
                :error="signupErrors.companyName"
              ></base-input>

              <base-input
                class="input-group-alternative signup-input mb-3"
                placeholder="Phone number"
                name="phone"
                id="phone"
                autocomplete="tel"
                aria-autocomplete="tel"
                v-model="model.phoneNumber"
              ></base-input>

              <base-input
                v-if="currentStoreId"
                class="input-group-alternative signup-input mb-3"
                placeholder="Store Website"
                v-model="model.shop"
              ></base-input>

              <base-input
                class="input-group-alternative signup-input mb-3"
                placeholder="Password"
                type="password"
                id="password"
                name="password"
                autocomplete="new-password"
                aria-autocomplete="new-password"
                v-model="model.password"
              ></base-input>

              <base-input
                class="input-group-alternative signup-input"
                placeholder="Confirm password"
                type="password"
                id="confirmpassword"
                name="confirmpassword"
                autocomplete="new-password"
                aria-autocomplete="new-password"
                v-on:keyup="confirmPasswordInputChanged($event)"
                v-model="model.password_confirmation"
                :error="signupErrors.passwordConfirmation"
              ></base-input>

              <div class="step-actions">
                <base-button
                  type="primary"
                  class="btn-signup"
                  :disabled="loading.welcome"
                  @click="signIn()"
                >
                  <div class="loader" v-if="loading.welcome"></div>
                  <span v-else>Sign in</span>
                </base-button>
                <base-button
                  type="primary"
                  class="btn-auth"
                  :disabled="loading.welcome"
                  @click="submitSignup"
                >
                  <div class="loader" v-if="loading.welcome"></div>
                  <span v-else>Sign up</span>
                </base-button>
              </div>
            </div>
            <div
              class="agreements"
              v-if="signupStep == SignUpSteps.WelcomeForm"
            >
              <div class="row my-2">
                <div class="col-12">
                  <base-checkbox
                    class="custom-control-alternative"
                    v-model="model.checkedTermsConditions"
                  >
                    <span class="text-muted">
                      I agree with the
                      <a
                        href="https://ninjainfluence.com/wp/terms-of-service/"
                        target="_blank"
                        >Terms and Conditions</a
                      >
                    </span>
                  </base-checkbox>
                  <div
                    class="text-danger invalid-feedback d-block"
                    v-if="signupErrors.checkedTermsConditions"
                  >
                    You must agree to the Terms and Conditions.
                  </div>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-12">
                  <base-checkbox
                    class="custom-control-alternative"
                    v-model="model.checkedReceiveMarketing"
                  >
                    <span class="text-muted">
                      By clicking you agree to share your email with Ninja
                      influence to receive marketing, updates and other emails
                      from Ninja influence team
                    </span>
                  </base-checkbox>
                </div>
              </div>
            </div>
          </form>

          <!-- <div class="credit-card-step-btns">
            <base-button
              type="primary"
              class="btn-back"
              @click="backToPricingPlan"
              :disabled="loading.signup"
            >
              Back
            </base-button>

            <base-button
              type="primary"
              class="btn-black wide"
              @click="submitSignup"
              :disabled="loading.signup"
            >
              Next
            </base-button>
          </div> -->
        </div>
      </div>

      <div class="auth-form">
        <form role="form">
          <vue-recaptcha
            ref="recaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            size="invisible"
            sitekey="6Lc6lFUcAAAAAECJb-i1Uy0lZCW8pvkalDcQ-50m"
          ></vue-recaptcha>

          <div class="col">
            <div class="text-danger text-center">
              <small>{{ localError }}</small>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div
      class="disclaimer"
      v-if="
        signupStep == SignUpSteps.PricingPlan ||
        signupStep == SignUpSteps.CreditCard
      "
    >
      You are now creating your free 14 days trial account "A whole world of
      influencer is waiting for you in one click. Please be aware that your card
      will automatically be charged by the end of the 14 days trial." If you
      wish not to continue please disconnect plan on the pricing page under the
      settings any time during the trial
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <!-- <a href="#" class="text-light">
                        <small>Forgot password?</small>
          </a>-->
      </div>
      <div class="col-6 text-right">
        <!-- <router-link to="/app/login" class="text-black">
          <small>Login into your account</small>
        </router-link> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { MESSAGES, AVAILABLE_INTEGRATIONS } from "@/constants";
import Configs from "../configs";

// https://app.ninjainfluence.com/app/signup?plan=gold_450_yearly
// https://app.ninjainfluence.com/app/signup?plan=gold_850_monthly
// https://app.ninjainfluence.com/app/signup?plan=platinum_650_yearly
// https://app.ninjainfluence.com/app/signup?plan=platinum_1050_monthly
// https://app.ninjainfluence.com/app/signup?plan=diamond_950_yearly
// https://app.ninjainfluence.com/app/signup?plan=diamond_1450_monthly

// https://app.ninjainfluence.com/app/subscription_webhook

let predefinedPlans = [
  "golden_9400_quarterly",
  "platinum_27600_monthly",
  "diamond_31500_monthly",
  "gold_450_yearly",
  "gold_850_monthly",
  "platinum_650_yearly",
  "platinum_1050_monthly",
  "diamond_950_yearly",
  "diamond_1450_monthly"
];

let SignUpSteps = {
  WelcomeForm: 0,
  PricingPlan: 1,
  CreditCard: 2
};

export default {
  name: "signup",
  components: {
    VueRecaptcha,
    StripeElementCard
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
    return {
      showMore: true,
      showAdditionalCosts: false,
      yearlySelected: false,
      SignUpSteps: SignUpSteps,
      configs: Configs,
      model: {
        email: "",
        fullName: null,
        companyName: null,
        phoneNumber: null,
        invite_token: "",
        password: "",
        password_confirmation: "",
        shop: "",
        state: "",
        promo: "",
        pricingPlan: null,
        checkedTermsConditions: false,
        checkedReceiveMarketing: true
      },
      currentStoreId: null,
      loading: {
        welcome: false,
        plans: false,
        signup: false
      },
      localError: null,
      signupStep: SignUpSteps.WelcomeForm,
      signupErrors: {
        email: null,
        fullName: null,
        companyName: null,
        passwordConfirmation: null,
        common: null,
        checkedTermsConditions: null
      }
    };
  },
  props: {
    store_id: {
      type: String,
      description: "store id"
    }
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert,
      availablePlans: (state) => state.billing.availablePlans
    }),
    planIsSelected: {
      get() {
        return this.model.pricingPlan;
      }
    }
  },
  mounted() {
    // to reload userpilot
    this.$store.dispatch("user/setRoute", this.$route.path);
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }
    if (this.$route.query.invite_token) {
      this.model.invite_token = this.$route.query.invite_token;
    }
    if (this.$route.query.promo) {
      this.model.promo = this.$route.query.promo;
    }
    if (this.store_id === AVAILABLE_INTEGRATIONS.shopify.id) {
      this.currentStoreId = this.store_id;
      this.model.shop = this.$route.query.shop;
      this.model.state = this.$route.query.state;
    } else {
      this.currentStoreId = null;
    }
    if (this.$route.query.plan) {
      if (predefinedPlans.includes(this.$route.query.plan.toLowerCase())) {
        this.model.pricingPlan = this.$route.query.plan;
      }
    }

    let recaptchaScript = document.createElement("script");
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    document.body.appendChild(recaptchaScript);
  },
  methods: {
    expandAdditionalCosts() {
      this.showMore = !this.showMore;
      this.showAdditionalCosts = !this.showAdditionalCosts;
    },
    togglePrice() {
      this.yearlySelected = !this.yearlySelected;
    },
    async submit() {
      const { email, password, password_confirmation, shop } = this.model;
      if (!email || !password || !password_confirmation) {
        this.localError = "Missing email or password.";
        return;
      } else if (password !== password_confirmation) {
        this.localError = "Passwords do not match.";
        return;
      } else if (this.currentStoreId && !shop) {
        this.localError = "Missing store website.";
        return;
      } else if (!this.checkedTermsConditions) {
        this.localError = "You must agree to the Terms and Conditions.";
        return;
      }
      this.$refs.recaptcha.execute();
    },
    confirmPasswordInputChanged(e) {
      if (e.keyCode === 13) {
        this.submitWelcomeForm();
      }
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    signIn() {
      this.$router.push("/app/login");
    },
    gtag_report_conversion(url) {
      var callback = function () {
        if (typeof url != "undefined") {
          window.location = url;
        }
      };
      gtag("event", "conversion", {
        send_to: "AW-10944536239/sMNvCP2Ky4sYEK-94eIo",
        event_callback: callback
      });
      return false;
    },
    submitWelcomeForm() {
      const {
        email,
        fullName,
        companyName,
        password,
        password_confirmation,
        shop,
        checkedTermsConditions
      } = this.model;
      if (!email) {
        this.signupErrors.email = "Email is required.";
        return;
      }
      if (!fullName) {
        this.signupErrors.fullName = "Full name is required.";
        return;
      }
      if (!companyName) {
        this.signupErrors.companyName = "Company name is required.";
        return;
      }
      if (password !== password_confirmation) {
        this.signupErrors.passwordConfirmation = "Passwords do not match.";
        return;
      }
      // if (this.currentStoreId && !shop) {
      //   this.signupErrors.common = "Missing store website.";
      //   return;
      // }
      if (!checkedTermsConditions) {
        this.signupErrors.checkedTermsConditions =
          "You must agree to the Terms and Conditions.";
        return;
      }

      this.gtag_report_conversion();

      // sh.sergey - currently plans are hardcoded
      //this.getPlans();
      if (!this.planIsSelected) this.signupStep = SignUpSteps.PricingPlan;
      else this.signupStep = SignUpSteps.CreditCard;
    },
    async getPlans() {
      this.loading.plans = true;
      try {
        const { data } = await this.$store.dispatch(
          "billing/getNewBillingPlans"
        );

        //this.availablePlans = data;
        this.loading.plans = false;
      } catch {
        this.loading.plans = false;
        this.localError = "Getting plans failed";
      }
    },
    choosePlan(plan) {
      if (plan === "professional_plan")
        // TODO: sh.sergey - open a chat widget or something similar about "contact us"-way
        return;

      this.model.pricingPlan = plan;

      this.signupStep = SignUpSteps.CreditCard;
    },
    submitSignup() {
      // Main method for submitting form

      // pre clean up
      this.recaptchaToken = null;

      // validaition if needed

      // Recaptcha -> create Stripe token -> sending form
      // onCaptchaVerified
      // tokenCreated
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
      
      this.loading.signup = true;
      
      // handle the token
      this.$refs.recaptcha.reset();
      try {
        const res = await this.$store.dispatch("user/signUpNew", {
          ...this.model,
          recaptcha_token: this.recaptchaToken
        });
        this.loading.signup = false;
        this.localError = null;

        this.gtag_report_conversion();

        if (res) {
          window.location.replace(`/app/payment?plan=${this.model.pricingPlan}`)

          // if (res.shopify_success) {
          //   window.location.replace(`/app/settings/integrations`);
          // } else if (res.shop) {
          //   window.location.replace(
          //     `/app/settings/integrations/shopify?shop=${res.shop}&state=${
          //       res.state ? res.state : ""
          //     }`
          //   );
          // } else {
          //   window.location.replace(`/app/payment&${this.model.pricingPlan}`);
          // }
        }
      } catch (err) {
        this.loading.signup = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__registration-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    onStripeError() {
      this.loading.signup = false;
    }
  },
  watch: {
    "model.email": {
      handler() {
        this.signupErrors.email = null;
      }
    },
    "model.fullName": {
      handler() {
        this.signupErrors.fullName = null;
      }
    },
    "model.companyName": {
      handler() {
        this.signupErrors.companyName = null;
      }
    },
    "model.password": {
      handler() {
        this.signupErrors.passwordConfirmation = null;
      }
    },
    "model.password_confirmation": {
      handler() {
        this.signupErrors.passwordConfirmation = null;
      }
    },
    "model.checkedTermsConditions": {
      handler() {
        this.signupErrors.checkedTermsConditions = null;
      }
    }
  }
};
</script>
<style lang="scss">
//@import "../assets/scss/pages/register";
@import "../assets/scss/pages/signup";
</style>
