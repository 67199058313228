<template>
  <div
    class="modal edit-lead-email-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' },
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a slot="left" @click="closePanel">
            <i class="fas fa-chevron-left mr-1"></i>
            <span>{{ lead.username }}</span>
          </a>
          <div slot="center">Thread</div>
        </modal-topbar>

        <div  class="modal-body">
          <h3 v-if="thread" class="mt-5">{{ thread[0].subject }}</h3>
          <div v-if="lead && thread" class="email-content">
            <div
              v-for="(conversation, index) in thread.filter(e => !e.is_draft)"
              :key="index"
              class="card mb-2 cursor-pointer"
            >
              <div
                v-if="!collapse[index]"
                class="card-body flex-middle p-3"
                @click="toggleCollapse(index)"
              >
                <div class="mr-1">
                  <avatar
                    :username="conversation.from ? conversation.from.name : conversation.from_name"
                    :size="32"
                    :src="getImageByEmailAddress(conversation.from ? conversation.from.address : conversation.from_address)"
                  >
                  </avatar>
                </div>
                <div class="email-container--preveiw">
                  <div class="flex-between">
                    <div class="email-name">
                      {{ conversation.from ? conversation.from.name : conversation.from_name }}
                    </div>
                    <div class="email-date">
                      {{ conversation.updated_at | FullDate }}
                    </div>
                  </div>
                  <div class="email-preview">
                    {{ conversation.body_preview }}
                  </div>
                </div>
              </div>
              <div v-else class="card-body p-3">
                <div
                  class="email-header flex-middle"
                  @click="toggleCollapse(index, $event)"
                >
                  <div class="mr-1">
                    <avatar
                      :username="conversation.from ? conversation.from.name : conversation.from_name"
                      :size="32"
                      :src="getImageByEmailAddress(conversation.from ? conversation.from.address : conversation.from_address)"
                    >
                    </avatar>
                  </div>
                  <div class="email-container--header">
                    <div class="flex-between">
                      <div class="email-name">
                        {{ conversation.from ? conversation.from.name : conversation.from_name }}
                        <span class="email-address--fade"
                          >&lt;{{ conversation.from ? conversation.from.address : conversation.from_address }}&gt;</span
                        >
                      </div>
                      <div class="email-date">
                        {{ conversation.updated_at | FullDate }}
                      </div>
                    </div>
                    <base-dropdown class="nav-item" position="left">
                      <a
                        slot="title"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        class="email-receivers cursor-pointer"
                      >
                        To
                        <span
                          v-for="(receiver,
                          rIndex) in conversation.to_recipients"
                          :key="rIndex"
                        >
                          {{ (rIndex ? ", " : "") + receiver.name }}
                        </span>
                        <i class="fas fa-angle-down ml-1"></i>
                      </a>
                      <li class="d-flex w-100">
                        <div class="label">
                          from:
                        </div>
                        <div class="value">
                          <strong>{{ conversation.from ? conversation.from.name : conversation.from_name }}</strong>
                          <span class="email-address--fade">
                            &lt;{{ conversation.from ? conversation.from.address : conversation.from_address }}&gt;</span
                          >
                        </div>
                      </li>
                      <li class="d-flex w-100">
                        <div class="label">
                          To
                        </div>
                        <div class="value">
                          <template
                            v-for="(receiver,
                            rIndex) in conversation.to_recipients"
                          >
                            <strong :key="rIndex">{{ receiver.name }}</strong>
                            <span
                              :key="receiver.address"
                              class="email-address--fade"
                            >
                              &lt;{{ receiver.address }}&gt;</span
                            >
                          </template>
                        </div>
                      </li>
                      <li
                        v-if="
                          conversation.cc_recipients &&
                            conversation.cc_recipients.length
                        "
                        class="d-flex w-100"
                      >
                        <div class="label">
                          Cc
                        </div>
                        <div class="value">
                          <template
                            v-for="(receiver,
                            rIndex) in conversation.cc_recipients"
                          >
                            <strong :key="rIndex">{{ receiver.name }}</strong>
                            <span
                              :key="receiver.address"
                              class="email-address--fade"
                            >
                              &lt;{{ receiver.address }}&gt;</span
                            >
                          </template>
                        </div>
                      </li>
                      <li class="d-flex w-100">
                        <div class="label">
                          date:
                        </div>
                        <div class="value">
                          {{ conversation.modified_at }}
                        </div>
                      </li>
                      <li class="d-flex w-100">
                        <div class="label">
                          subject:
                        </div>
                        <div class="value">
                          {{ conversation.subject }}
                        </div>
                      </li>
                    </base-dropdown>
                  </div>
                </div>
                <div class="email-container--body">
                  <VueDocPreview :value="removeImage(index)" type="code" />
                </div>
              </div>
              <div class="email-action">
                <div class="btn-group mr-1" role="group" aria-label="Email action">
                  <base-button
                    v-if="conversation.weblink"
                    v-b-tooltip.hover
                    title="View in your inbox"
                    type="primary" icon="fas fa-eye" class="" @click="openInbox(index)"
                  />

                  <base-button
                    v-if="isNotMine(index)"
                    v-b-tooltip.hover
                    :disabled="!hasFullAccess('emails')"
                    :disabledTooltip="getPermissionErrorMessage('emails:full')"
                    title="Reply"
                    type="primary"
                    icon="fas fa-reply"
                    @click="reply(index)"
                  />

                  <!-- <base-button
                    v-b-tooltip.hover
                    title="Forward"
                    type="primary" icon="fas fa-share" class="" @click="forward(index)"
                  /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2 w-100">
            <label>Reply</label>
            <quill-editor
              ref="threadQuillEditor"
              v-model="content"
              :option="editorOption"
            ></quill-editor>
            <div class="flex-center">
              <base-button
                :disabled="!hasFullAccess('emails')"
                :disabledTooltip="getPermissionErrorMessage('emails:full')"
                class="btn-reply btn-w-25 flex-middle-center"
                type="primary"
                @click="replyDirectly(lead)"
                :isBusy="loading.send"
              >
                  Reply
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* components */
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Avatar from "vue-avatar";
import VueDocPreview from "vue-doc-preview";
/* functions/constants */
import { getPermissionErrorMessage } from "@/utils";
import { SOCIALS, MESSAGES, AVAILABLE_EMAILS } from "@/constants";
import { mapState, mapGetters } from "vuex";

export default {
  name: "edit-lead-email-panel",
  components: {
    Avatar,
    quillEditor,
    VueDocPreview,
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration",
    },
    threadId: {
      type: String,
    },
    username: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      lead: (state) => state.campaign.lead,
      threads: (state) => state.campaign.threads,
      emails: state => state.integration.emails,
      conversation: (state) => state.campaign.conversation,
    }),
    ...mapGetters({
      hasFullAccess: 'user/hasFullAccess',
      hasViewAccess: 'user/hasViewAccess',
    }),
    thread() {
      return (this.threads && this.threads[this.threadId]) || null;
    },
    editor() {
      return this.$refs.threadQuillEditor.quill;
    },
  },
  mounted() {
    if (!this.resizeHandler) {
      this.resizeHandler = window.addEventListener("resize", this.resizeWindow);
    }
    this.resizeWindow();
  },
  beforeDestroy() {
    if (this.resizeHandler) {
      window.removeEventListener("resize", this.resizeWindow);
      this.resizeHandler = null;
    }
  },
  data() {
    return {
      socials: SOCIALS,
      getPermissionErrorMessage: getPermissionErrorMessage,
      collapse: [],
      leadPictureUrl: null,
      content: "",
      editorOption: {
        theme: "snow",
        placeholder: "Your new placeholder!",
        modules: {
        },
      },
      resizeHandler: null,
      loading: {
        send: false,
      },
      localError: false,
    };
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".edit-lead-email-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    resizeWindow() {
      const quillToolbarHeight = document.querySelector(".edit-lead-email-panel .quill-editor .ql-toolbar").clientHeight;
      document.querySelector(".edit-lead-email-panel .btn-reply").style.marginTop = quillToolbarHeight * 1 + 15 + "px";
    },
    removeImage(index) {
      let doc = document.createElement('div');
      let isFirst = true;
      doc.innerHTML = this.thread[index].body;
      doc.querySelectorAll("img").forEach(img => {
        const span = document.createElement('span');
        if (isFirst) {
          if (this.thread[index].web_link) {
            const a = document.createElement('a');
            const span1 = document.createElement('span');
            const span2 = document.createElement('span');
            span1.innerHTML="Click ";
            span2.innerHTML=" to view this email in your inbox ";
            a.href = this.thread[index].web_link;
            a.innerHTML = "here";
            span.appendChild(span1);
            span.appendChild(a);
            span.appendChild(span2);
          } else {
            span.innerHTML = "This email contains image attachments, which are not supported by Ninja Influence right now.";
          }
          span.setAttribute("style", "color: red; font-size: 0.8rem;");
          isFirst = false;
        }
        img.parentNode.replaceChild(span, img);
      });
      return doc.innerHTML;
    },
    async replyDirectly() {
      let lastConversation;
      // this.thread.forEach((e, i) => {
      //   if (this.isNotMine(i)) {
      //     lastConversation = e;
      //   }
      // });
      lastConversation = this.thread[this.thread.length - 1];
      this.loading.send = true;
      try {
        await this.$store.dispatch("campaign/replyEmail", {
          name: this.conversation.from ? this.conversation.from.name : this.conversation.from_name,
          address: this.conversation.from ? this.conversation.from.address : this.conversation.from_address,
          message_id: lastConversation.id,
          reply_meta: {
            body: this.content,
            to_recipients: this.conversation.to,
            cc_recipients: this.conversation.cc,
          }
        });
        this.loading.send = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: "Message sent!",
          alertClass: "success",
        });
      } catch(error) {
        this.loading.send = false;
        if (error.response.status === 409 && error.response.data.hasOwnProperty("user_message_id")) {
          const email = error.response.data ? error.response.data.metadata : null;
          if (error.response.data.user_message_id[1] === "email__invalid-auth") {
            this.$emit("invalidEmailToken", email);
          } else if (!this.showAlert) {
            const message = (MESSAGES[error.response.data.user_message_id[1]] || MESSAGES["common-500"]).replace("{{email_provider}}", email ? AVAILABLE_EMAILS[email.name].name : "The email provider");
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: message,
              alertClass: "danger",
            });
          }
        } else if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    reply(index) {
      this.$store.dispatch("campaign/setConversation", {
        subject: this.thread[index ? index : 0].subject,
        thread_id: this.threadId,
        conversation_index: index,
        is_reply: true,
      });
      this.$emit("reply");
    },
    forward(index) {
      this.$store.dispatch("campaign/setConversation", {
        subject: this.thread[index ? index : 0].subject,
        thread_id: this.threadId,
        conversation_index: index,
        is_reply: false,
      });
      this.$emit("forward");
    },
    openInbox(index) {
      const win = window.open(this.thread[index ? index : 0].web_link);
      if (win == null || typeof(win) == 'undefined') {
        // Turn off the pop-up blocker
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__email_popup-failed"],
          alertClass: "danger",
        });
      } else {
        win.focus();
      }
    },
    isNotMine(index) {
      const allEmails = [...(this.emails.gmail && this.emails.gmail.length ? this.emails.gmail : []),
        ...(this.emails.outlook && this.emails.outlook.length ? this.emails.outlook : [])];
      return allEmails.map(e=>e.address).indexOf(
        this.thread[index ? index : 0].from ? this.thread[index ? index : 0].from.address : this.thread[index ? index : 0].from_address
      ) === -1;
    },
    toggleCollapse(index, e) {
      if (e) {
        let hasAction = false;
        e.path.forEach((element) => {
          if (
            element.tagName === "A" ||
            element.tagName === "UL" ||
            element.tagName === "BUTTON"
          ) {
            hasAction = true;
          }
        });
        if (hasAction) return;
      }
      this.collapse[index] = !this.collapse[index];
      this.collapse = [...this.collapse];
    },
    getImageByEmailAddress(email) {
      const index = this.lead.data.emails.indexOf(email);
      if (index > -1) {
        return this.lead.profile_pic;
      }
      return null;
    },
  },

  watch: {
    show(val) {
      this.collapse = [];
      const documentClasses = document.body.classList;
      this.editor.root.setAttribute("data-placeholder", "");
      if (val) {
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".edit-lead-email-panel .modal-dialog"
          ).style.right = `0px`;
          if (document.querySelector(".edit-lead-email-panel button.ql-image")) {
            document.querySelector(".edit-lead-email-panel button.ql-image").style.display = "none";
            document.querySelector(".edit-lead-email-panel button.ql-video").style.display = "none";
          }
          this.resizeWindow();
        }, 0);
        if (this.thread && this.thread.length == 1) {
          this.collapse[0] = 1;
        }
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".edit-lead-email-panel").scrollTop = 0;
      }
    },
    lead(val) {
      if (val.emails && val.emails.length) {
        this.selectedEmail = {
          ...(this.selectedEmail || {}),
          lead_email: val.emails[0],
        };
      }
    },
  },
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
