<template>
  <div>
    <div
      class="modal edit-lead-coupon-panel right-sliding-panel"
      :class="[
        { 'show d-block': show },
        { 'd-none': !show },
        { 'modal-mini': type === 'mini' }
      ]"
      v-show="show"
      tabindex="-1"
      role="dialog"
      :aria-hidden="!show"
    >
      <div class="modal-dialog mb-0">
        <div class="modal-content">
          <modal-topbar>
            <a slot="left" v-on:click="closePanel">
              <i class="fas fa-chevron-left mr-1"></i>
              <span>{{ lead.username }}</span>
            </a>
            <div slot="center">Coupons</div>
          </modal-topbar>

          <div class="modal-body p-0">
            <div class="main-content--coupon mt-5">
              <div class="d-flex flex-wrap justify-content-between px-4">
                <div class="flex-middle flex-wrap w-100">
                  <base-input
                    label="Filter"
                    class="input-group-alternative w-100"
                    placeholder="Code"
                    v-model="filterCode"
                    type="text"
                  ></base-input>
                </div>
              </div>

              <div class="coupons-container px-4">
                <div class="coupons-tags">
                  <div
                    v-for="(coupon, index) in coupons"
                    class="tag"
                    :key="index"
                  >
                    <img
                      class="integration-icon"
                      :src="
                        availableIntegrations[coupon.integration_name].image
                      "
                    />
                    <span
                      >{{ coupon.code }} ({{
                        coupon.integration_url | Domain
                      }})</span
                    >
                    <a class="btn-delete" @click="deleteCoupon(coupon)"
                      >&times;</a
                    >
                  </div>
                </div>
                <template v-for="(subCoupons, index) in availableCoupons">
                  <div :key="index" v-if="isShowableAvailableSubCoupons(index)">
                    <a
                      class="integration-node"
                      v-b-toggle="'accordion-' + index"
                      variant="info"
                    >
                      <i class="fas fa-chevron-down mr-1"></i>
                      <img
                        class="integration-icon"
                        :src="
                          availableIntegrations[subCoupons.integration_name]
                            .image
                        "
                        :alt="
                          availableIntegrations[subCoupons.integration_name]
                            .name
                        "
                      />
                      <span v-if="subCoupons.store_name">{{
                        subCoupons.store_name
                      }}</span>
                      <span v-else>{{
                        subCoupons.integration_url | Domain
                      }}</span>
                    </a>
                    <b-collapse
                      :id="'accordion-' + index"
                      role="tabpanel"
                      :visible="true"
                    >
                      <template v-for="code in subCoupons.coupons">
                        <a
                          v-if="isShowableAvailableCoupon(index, code)"
                          :key="code"
                          class="coupon-node"
                          @click="
                            addCoupon({
                              code: code,
                              index: index
                            })
                          "
                        >
                          <card>
                            <span>{{ code }}</span>
                          </card>
                        </a>
                      </template>
                    </b-collapse>
                  </div>
                </template>
                <div v-if="loading.coupons" class="flex-middle-center">
                  <base-spinner></base-spinner>
                  <span class="ml-2 p-5">Loading...</span>
                </div>
                <p v-else-if="!hasSearchResult" class="w-100 p-5 text-center">
                  No coupons found
                </p>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <div class="flex-center w-100">
              <base-button
                type="primary"
                class="btn-w-25"
                @click="closePanel()"
              >
                <span v-if="isChanged">Save</span>
                <span v-else>Close</span>
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      :show="showConfirmCouponConflict"
      @close="showConfirmCouponConflict = false"
    >
      <h3 slot="header">Duplicate coupon</h3>
      <p>
        This coupon has already been used with other influencers or campaigns.
        We strongly discourage attaching the same coupon to multiple
        influencers, because then the revenue tracked by this coupon will be
        split between all influencers and campaigns sharing it.
      </p>
      <p>Are you sure you want to add it?</p>
      <div slot="footer" class="d-flex">
        <base-button type="danger" @click="forceAddCoupon">Add</base-button>
        <base-button type="primary" @click="showConfirmCouponConflict = false"
          >Cancel</base-button
        >
      </div>
    </modal>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { MESSAGES, AVAILABLE_INTEGRATIONS } from "@/constants";

export default {
  name: "edit-lead-coupon-panel",
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    username: {
      type: String
    },
    campaign: {
      type: Object
    },
    lead: {
      type: Object
    }
  },
  data() {
    return {
      loading: {
        coupons: false,
        add: false,
        delete: false
      },
      isChanged: false,
      availableIntegrations: AVAILABLE_INTEGRATIONS,
      integration: null,
      filterCode: "",
      selectedCoupon: null,
      inputError: {
        code: false
      },
      showConfirmCouponConflict: false
    };
  },
  computed: {
    ...mapState({
      integrations: (state) => state.integration.integrations,
      coupons: (state) => state.campaign.coupons,
      availableCoupons: (state) => state.campaign.available_coupons,
      showAlert: (state) => state.alert.showAlert
    }),
    isValidCode: {
      get() {
        return this.filterCode.trim();
      }
    },
    hasSearchResult: {
      get() {
        if (!this.availableCoupons || !this.availableCoupons.length)
          return false;
        let results = 0;
        this.availableCoupons.forEach((e, index) => {
          results += this.isShowableAvailableSubCoupons(index) ? 1 : 0;
        });
        return results ? true : false;
      }
    }
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".edit-lead-coupon-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    isShowableAvailableCoupon(index, code) {
      const cIndex = this.coupons.map((c) => c.code).indexOf(code);
      return (
        !code ||
        (code.toLowerCase().includes(this.filterCode.toLowerCase()) &&
          cIndex < 0)
      );
    },
    isShowableAvailableSubCoupons(index) {
      let show = false;
      this.availableCoupons[index].coupons.forEach((code) => {
        if (this.isShowableAvailableCoupon(index, code)) {
          show = true;
        }
      });
      return show;
    },
    getIntegrationId(index) {
      if (
        !this.integrations ||
        !this.integrations.length ||
        !this.availableCoupons ||
        !this.availableCoupons.length ||
        !this.availableCoupons[index]
      )
        return;
      const eIndex = this.integrations
        .map((e) => e.integration_url)
        .indexOf(this.availableCoupons[index].url);
      return this.integrations[eIndex].id;
    },
    forceAddCoupon() {
      this.showConfirmCouponConflict = false;
      this.addCoupon(this.selectedCoupon, true);
    },
    // forceAddCoupon() {
    //   this.showConfirmCouponConflict = false;
    //   this.$refs.editLeadCoupon.forceAddCoupon();
    // },
    async addCoupon(coupon, isForced) {
      const { index, code } = (this.selectedCoupon = coupon);
      const integrationId = this.availableCoupons[index].integration_id;
      this.loading.add = true;
      try {
        await this.$store.dispatch("campaign/addCoupon", {
          campaign_id: this.campaign.id,
          lead_id: this.lead.id,
          integration_id: integrationId,
          code: code,
          allow_duplicates: isForced ? true : false
        });
        this.isChanged = true;
        this.loading.add = false;
      } catch (error) {
        if (!error.response || error.response.status !== 409) {
          this.loading.add = false;
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        } else {
          this.hasCouponConflictAtAdding();
          this.$emit("conflict");
        }
      }
    },
    hasCouponConflictAtAdding() {
      this.showConfirmCouponConflict = true;
    },
    async deleteCoupon(coupon) {
      const { integration_id, code } = coupon;
      this.loading.delete = true;
      try {
        await this.$store.dispatch("campaign/deleteCoupon", {
          campaign_id: this.campaign.id,
          lead_id: this.lead.id,
          integration_id: integration_id,
          code: code
        });
        this.isChanged = true;
        this.loading.delete = false;
      } catch {
        this.loading.delete = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["common-500"],
          alertClass: "danger"
        });
      }
    },
    async getAvailableCoupons() {
      this.loading.coupons = true;
      try {
        await this.$store.dispatch("campaign/getAvailableCoupons");
        this.loading.coupons = false;
      } catch {
        this.loading.coupons = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["common-500"],
          alertClass: "danger"
        });
      }
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      this.getAvailableCoupons();
      if (val) {
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".edit-lead-coupon-panel .modal-dialog"
          ).style.right = `0px`;

          let trackingLinkElement = document.querySelector(
            "#input-tracking_link"
          );
          if (trackingLinkElement) trackingLinkElement.focus();
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".edit-lead-coupon-panel").scrollTop = 0;
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
