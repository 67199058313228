<template>
  <div class="main-content bg-main">
    <!-- Page content -->
    <div class="auth-layout container-fluid pt-1 pb-1">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Configs from "../configs";

export default {
  name: "auth-layout",
  computed: {
    ...mapState({
      alert: state => state.alert,
      showAlert: state => state.alert.showAlert
    })
  },
  watch: {
    showAlert: function(show) {
      if (show) {
        this.$notifications.setOptions({ type: this.alert.alertClass });
        // passing alert obj to account for customButton if included in obj
        this.$notifications.notify(this.alert);
      }
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      configs: Configs,
      showMenu: false
    };
  }
};
</script>
<style lang="scss">
@import "../assets/scss/layouts/auth-layout";
</style>
