<template>
  <div
    class="modal edit-lead-notes-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a slot="left" @click="closePanel">
            <i class="fas fa-chevron-left mr-1"></i>
            <span v-if="lead">{{ lead.username }}</span>
          </a>
          <div slot="center">Notes</div>
        </modal-topbar>

        <div v-if="lead" class="modal-body">
          <div class="row mt-3">
            <div class="col-12">
              <quill-editor
                class="mt-3"
                ref="myQuillEditor"
                v-model="content"
                :option="editorOption"
              ></quill-editor>
            </div>
          </div>
          <div class="flex-center mt-7">
              <base-button
                type="outline-light"
                class="btn-w-25 mr-4"
                @click="closePanel"
                >Cancel</base-button
              >
              <base-button
                v-if="noteId"
                type="primary"
                class="btn-w-25"
                @click="updateNote"
                >Save note</base-button
              >
              <base-button
                v-else
                type="primary"
                class="btn-w-25"
                @click="createNote"
                >Save note</base-button
              >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MESSAGES } from "@/constants";
import { mapState } from "vuex";

/* components */
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "edit-lead-notes-panel",
  components: {
    quillEditor
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    username: {
      type: String
    },
    lead: {
      type: Object
    },
    campaignId: {
      type: String
    },
    editorOption: {
      theme: "snow",
      placeholder: 'Your new placeholder!',
      modules: {
        topbar: [
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ["link", "image"],
          ["clean"]
        ]
      }
    },
    note: null
  },
  data() {
    return {
      loading: {
        save: false
      },
      content: "",
      noteId: null
    };
  },
  computed: {
    ...mapState({
      showAlert: state => state.alert.showAlert
    }),
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".edit-lead-notes-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    onEditorChange({ html }) {
      this.content = html;
    },
    async createNote() {
      this.loading.save = true;
      try {
        const { data } = await this.$store.dispatch("campaign/createLeadNote", {
          campaign_id: this.campaignId,
          lead_id: this.lead.id,
          content: this.content
        });
        this.noteId = data.note_id;
        this.loading.save = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["campaign__save_lead_note-success"],
          alertClass: "success"
        });
        this.closePanel();
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__save_lead_note-failed"],
            alertClass: "danger"
          });
        }
        this.loading.save = false;
      }
    },

    async updateNote() {
      this.loading.save = true;
      try {
        await this.$store.dispatch("campaign/updateLeadNote", {
          campaign_id: this.campaignId,
          lead_id: this.lead.id,
          content: this.content,
          note_id: this.noteId
        });
        this.loading.save = false;

        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["campaign__save_lead_note-success"],
          alertClass: "success"
        });
        this.closePanel();
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__save_lead_note-failed"],
            alertClass: "danger"
          });
        }
        this.loading.save = false;
      }
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      this.content = "";
      this.noteId = null;
      this.editor.root.setAttribute('data-placeholder', "");
      if (this.note) {
        this.content = this.note.content;
        this.noteId = this.note.id;
      }
      if (val) {
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".edit-lead-notes-panel .modal-dialog"
          ).style.right = `0px`;
          if (document.querySelector(".edit-lead-notes-panel button.ql-image")) {
            document.querySelector(".edit-lead-notes-panel button.ql-image").style.display = "none";
            document.querySelector(".edit-lead-notes-panel button.ql-video").style.display = "none";
          }
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".edit-lead-notes-panel").scrollTop = 0;
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
