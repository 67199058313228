<template>
  <div class="page-container controls-page">
    <div class="page-container-header">
      <div class="header-text">Controls page</div>
    </div>

    <div class="page-container-content">
        <div class="block-title">Page description</div>
        <div class="block-content">
            Page descritpion content

            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque bibendum nunc in lectus blandit, ac convallis sapien rutrum. Phasellus lobortis efficitur augue at pulvinar. Ut vehicula, risus lacinia elementum aliquam, mi risus ultrices sapien, eget malesuada tellus sapien ut sem. Morbi sed neque enim. Etiam imperdiet fringilla iaculis. Nullam at sem consequat, fringilla eros id, sodales magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse platea dictumst. Morbi dictum feugiat diam vitae efficitur.
        </div>
        <div class="block-title">Page description</div>
        <div class="block-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque bibendum nunc in lectus blandit, ac convallis sapien rutrum. Phasellus lobortis efficitur augue at pulvinar. Ut vehicula, risus lacinia elementum aliquam, mi risus ultrices sapien, eget malesuada tellus sapien ut sem. Morbi sed neque enim. Etiam imperdiet fringilla iaculis. Nullam at sem consequat, fringilla eros id, sodales magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse platea dictumst. Morbi dictum feugiat diam vitae efficitur.
        </div>
        <div class="block-title">Page description</div>
        <div class="block-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque bibendum nunc in lectus blandit, ac convallis sapien rutrum. Phasellus lobortis efficitur augue at pulvinar. Ut vehicula, risus lacinia elementum aliquam, mi risus ultrices sapien, eget malesuada tellus sapien ut sem. Morbi sed neque enim. Etiam imperdiet fringilla iaculis. Nullam at sem consequat, fringilla eros id, sodales magna. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse platea dictumst. Morbi dictum feugiat diam vitae efficitur.
        </div>
    </div>
  </div>
</template>


<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>


<style lang="scss">
@import "../assets/scss/pages/controls";
</style>