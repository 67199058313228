<template>
  <div
    class="modal side-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    @click.self="closePanel"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content side-panel-content">
        <div class="side-panel-topbar">
          <div class="side-panel-topbar-left">
            <a slot="left" @click="closePanel" class="back-btn">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <div class="side-panel-topbar-right">
            <slot name="topbar-actions"> </slot>
          </div>
        </div>
        <div class="modal-body">
          <slot> 

          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "side-panel",
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        if (value && value.length) {
          let acceptedValues = ["", "notice", "mini"];
          return acceptedValues.indexOf(value) !== -1;
        }
        return -1;
      },
      description: 'Modal type (notice|mini|"") '
    }
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(".side-panel .modal-dialog").style.right = `-100%`;

      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    }
  },
  watch: {
    async show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        documentClasses.add("modal-open");

        setTimeout(() => {
          document.querySelector(
            ".side-panel .modal-dialog"
          ).style.right = `0px`;
        }, 100);

        this.$emit("update:show", true);

      } else {
        documentClasses.remove("modal-open");


        //this.$emit("update:show", false);
      }
    }
  }
};
</script>


<style lang="scss">
@import "../../assets/scss/custom/functions";
@import "../../assets/scss/custom/variables";

.side-panel {
  .side-panel-content {
    display: flex;
    flex-direction: column;

    .side-panel-topbar {
      background-color: #eceff1;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      padding: 10px 30px;

      .side-panel-topbar-left {
        display: flex;

        .back-btn {
          cursor: pointer;
        }
      }

      .side-panel-topbar-right {
        display: flex;
      }
    }

    // .lead-header {
    //   flex: 0 1 auto;
    // }

    // .lead-tabs {
    //   flex: 1 1 auto;

    //   display: flex;

    //   > div {
    //     flex: 1 1 auto;
    //   }
    // }
  }
}
</style>