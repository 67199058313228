<template>
  <div class="help-page">
    <div class="page-title">Help and learning page</div>
    <div class="block-title">User</div>
    <div class="user-block">
      <div class="greetings-card">
        Hi, we're here to help. On this page you'll find updates, announcements,
        and tutorials.
      </div>
    </div>
    <div class="block-title">Information for you</div>
    <div class="cards-block">
      <div class="card-item">
        <div class="video-title">Ninja Influence - Episode 1 Overview</div>
        <div class="video-card">
          <a
            href="https://www.loom.com/share/b5d579511c054fa897d50fdaac5c3a8c"
            target="_blank"
          >
            <img
              class="loom-preview"
              src="https://cdn.loom.com/sessions/thumbnails/b5d579511c054fa897d50fdaac5c3a8c-with-play.gif"
            />
          </a>
        </div>
      </div>
      <div class="card-item">
        <div class="video-title">Ninja Influence - Episode 2 Home</div>
        <div class="video-card">
          <a
            href="https://www.loom.com/share/cbcdc64d1735451dae1666184ee4a1b0"
            target="_blank"
          >
            <img
              class="loom-preview"
              src="https://cdn.loom.com/sessions/thumbnails/cbcdc64d1735451dae1666184ee4a1b0-with-play.gif"
            />
          </a>
        </div>
      </div>
      <div class="card-item">
        <div class="video-title">Ninja Influence - Episode 3 Search/IG</div>
        <div class="video-card">
          <a
            href="https://www.loom.com/share/031518d3b57f4d6db28f3b2c6d4782cf"
            target="_blank"
          >
            <img
              class="loom-preview"
              src="https://cdn.loom.com/sessions/thumbnails/031518d3b57f4d6db28f3b2c6d4782cf-with-play.gif"
            />
          </a>
        </div>
      </div>
      <div class="card-item">
        <div class="video-title">Ninja Influence - Episode 4 Search</div>
        <div class="video-card">
          <a
            href="https://www.loom.com/share/beab83458abc4f57b1dd1716424c3d78"
            target="_blank"
          >
            <img
              class="loom-preview"
              src="https://cdn.loom.com/sessions/thumbnails/beab83458abc4f57b1dd1716424c3d78-with-play.gif"
            />
          </a>
        </div>
      </div>
      <div class="card-item">
        <div class="video-title">Ninja Influence - Episode 5 Campaigns</div>
        <div class="video-card">
          <a
            href="https://www.loom.com/share/fbe6a184d1704d16bfad254090b84512"
            target="_blank"
          >
            <img
              class="loom-preview"
              src="https://cdn.loom.com/sessions/thumbnails/fbe6a184d1704d16bfad254090b84512-with-play.gif"
            />
          </a>
        </div>
      </div>
      <div class="card-item">
        <div class="video-title">Ninja Influence - Episode 6 Workspace</div>

        <div class="video-card">
          <a
            href="https://www.loom.com/share/143ed9bfd7b0435fb8627ec1f994a27a"
            target="_blank"
          >
            <img
              class="loom-preview"
              src="https://cdn.loom.com/sessions/thumbnails/143ed9bfd7b0435fb8627ec1f994a27a-with-play.gif"
            />
          </a>
        </div>
      </div>
      <div class="card-item">
        <div class="video-title">Ninja Influence - Episode 7 Inbox</div>

        <div class="video-card">
          <a
            href="https://www.loom.com/share/e106896977ae4da6a631ce5a47d2da49"
            target="_blank"
          >
            <img
              class="loom-preview"
              src="https://cdn.loom.com/sessions/thumbnails/e106896977ae4da6a631ce5a47d2da49-with-play.gif"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {};
  },
  props: {},
  computed: {},
  mounted() {},
  methods: {},
  watch: {}
};
</script>



<style lang="scss">
@import "../assets/scss/pages/help";
</style>